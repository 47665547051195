
.container {
  width: 300px;
  height: 350px;
  border: 3px solid black;
  border-radius: 20px;
  margin-left: 39.5vw;
  margin-top: 10vh;
  text-align: center;
  background:linear-gradient(rgb(163, 243, 222),rgb(139, 244, 244))
}
.container img {
  width: 250px;
  height: 250px;
  margin-top: 20px;
  border-radius: 10px;
}
#input{
    position: fixed;
    margin-top: 500px;
    margin-left: 200px;
}
.id{
    background-color: black;
    color: white;
    width:40px;
    height: 40px;
    font-size: 30px;
    font-weight: 500;
    border-radius: 50%;
    position: relative;
    top: -315px;
    left: 270px;
    align-items: center;
}
.name1{
    position: relative;
    top: -90px;left: 90px;
    display: flex;
    gap: 10px;
    text-align: center;
}
.name{
    font-size: 20px;
    font-weight: 500;
    
}
.form{
    margin-top: 10px;
}
.form input{
    width: 15vw;
    height: 4vh;
    font-size: 15px;
    font-weight: 600;
    text-align: center;
    border: 2px solid black;
    border-radius: 5px;
    background-color: beige;
}